import request from './request'

export default class ClubsService {
  static getById(id, section?: string[]): any {
    return request({
      url: `/customers/${id}`,
      method: 'GET',
      params: {
        section,
      },
    })
  }

  static getSearchResults(params: {
    query: string
    past_event?: boolean
    event_type?: string[]
    customer_id: string
    include_cities?: boolean
    include_events?: boolean
    include_zip_codes?: boolean
  }): any {
    return request({
      url: `/events/search`,
      method: 'GET',
      params,
    })
  }
}
