import { AxiosError } from 'axios'
import { UseQueryOptions } from '@tanstack/react-query'
import HubsService from 'src/api/Hubs'
import get from 'lodash/get'
import useGolfQuery from './useGolfQuery'
import { HubType } from 'src/Types/Hub'

const getHubById = async (id): Promise<HubType> => {
  if (id !== undefined) {
    const data = await HubsService.getById(id)
    return data
  }

  return null
}

export default function useHub({ id }, options?: UseQueryOptions<HubType, AxiosError, HubType>) {
  const { data, isLoading, error } = useGolfQuery([id], () => getHubById(id), options)

  const errorStatus = get(error, 'status', 0)
  const notFound = error && errorStatus / 100 === 4

  return { hub: data, isLoading, notFound }
}
