import { HubCustomizationSettings } from 'src/Types/Hub'

const initialState: any = {
  customizationSettings: {},
}

export const setCustomizationSettings = (customizationSettings: HubCustomizationSettings) => ({
  type: 'SET_CUSTOMIZATION_SETTINGS',
  payload: {
    ...customizationSettings,
  },
})

const hubReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_CUSTOMIZATION_SETTINGS':
      return {
        ...state,
        customizationSettings: {
          ...action.payload,
        },
      }
    default:
      return state
  }
}

export const getHubCustomizationSettings = (state) => state.customizationSettings

export default hubReducer
