import React from 'react'
import iconMap from 'src/assets/images/icons/icon-map'

export interface IconProps {
  name: string
  color?: string
  className?: string
  onClick?: (...args: any[]) => any
}

const Icon = (props: IconProps) => {
  const Icon = iconMap[props.name]

  const colorPalette = {
    // Grey
    ultra_light_grey: '#eeefef',
    light_grey: '#c1c5c8',
    medium_grey: '#8a8c8e',
    dark_grey: '#636466',
    ultra_dark_grey: '#333',
    // Orange
    $orange_800: 'var(--primary-color-darker-1)',
    $orange_700: 'var(--primary-color)',
    $orange_600: 'var(--primary-color-lighter-1)',
    $orange_500: 'var(--primary-color-lighter-2)',
    $orange_400: 'var(--primary-color-lighter-3)',
    $orange_300: 'var(--primary-color-lighter-4)',
    $orange_200: 'var(--primary-color-lighter-5)',
    $orange_100: 'var(--primary-color-lighter-6)',
    // Blue
    blue_800: '#003365',
    blue_700: '#005486',
    blue_600: '#2872a3',
    blue_500: '#518ebc',
    blue_400: '#79aad1',
    blue_300: '#a2c5e3',
    blue_200: '#cadef1',
    blue_100: '#f2f7fc',

    // Tag colors
    orange_tag_1: '#ff6633',
    blue_tag_1: '#00a0ce',
    blue_tag_2: '#005486',
    red_tag_1: '#ea1600',

    // Status colors
    error: '#ff3c3c',
    error_2: '#ff6157',
    yellow_warning: '#ffc755',
    green_1: '#67c297',

    // Other Colors
    blue_facebook: '#4267b2',
    white: '#fff',
  }

  return (
    <Icon
      color={props.color ? colorPalette[props.color] : 'currentColor'}
      className={props.className}
      onClick={props.onClick}
    />
  )
}

export default Icon
