import request from './request'

export default class HubsService {
  static getById(id, section?: string[]): any {
    return request({
      url: `/hubs/${id}`,
      method: 'GET',
      params: {
        section,
      },
    })
  }

  static getSearchResults(
    hubId: string,
    query: string,
    past_event = false,
    include_cities,
    include_customers,
    include_events,
    includeZipCodes,
  ): any {
    return request({
      url: `/hubs/${hubId}/search?query=${query}&past_event=${past_event}&include_cities=${include_cities}&include_customers=${include_customers}&include_events=${include_events}&include_zip_codes=${includeZipCodes}`,
      method: 'GET',
    })
  }
}
