export const isTGACustomer = (customerId) => {
  return (
    customerId === 'd5ef9bcb-f5bf-4c9e-b750-c3f0dffc8a7d' ||
    customerId === 'e0efddd8-b6c7-41e4-bbff-bbfadd9f753e' ||
    customerId === 'c4f5b566-57c6-4aec-915d-767d9141a797' ||
    (customerId === '4a4e39b1-fca5-4caa-9dcf-98d068b2c55b' && process.env.REACT_APP_BASE_URL === 'stg')
  )
}

export const isTwilightCustomer = (customerId) => {
  return customerId === 'e0efddd8-b6c7-41e4-bbff-bbfadd9f753e'
}
