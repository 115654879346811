import { UseQueryOptions } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import get from 'lodash/get'
import EventsService from 'src/api/Events'
import { EventType } from 'src/Types/Event'
import useGolfQuery from './useGolfQuery'

const getEventById = async (id): Promise<EventType> => {
  const data = await EventsService.getById(id)

  return data
}

export default function useEvent(id, options?: UseQueryOptions<EventType, AxiosError, EventType>) {
  const { data, isLoading, error } = useGolfQuery(['event', id], () => getEventById(id), options)

  const errorStatus = get(error, 'status', get(error, 'data.errors[0].status', 0))
  const hasAddons = data?.addons?.length > 0
  const isFavorite = data?.is_user_favorite_event
  const continueRegistrationId = data?.continue_registration_id
  const isCompleted = data?.registration_is_completed
  const unauthorized = errorStatus === 401
  const notFound = error && errorStatus / 100 === 4

  return { event: data, isLoading, hasAddons, isFavorite, continueRegistrationId, notFound, unauthorized, isCompleted }
}
